<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';

const rawNavigationElements = useNavigation({
  type: 'footer-navigation',
}).navigationElements;

const navigationElements = computed(
  () =>
    rawNavigationElements.value?.filter(
      (navigationElement) => navigationElement.name !== 'legal',
    ) || [],
);

const legalNavigationElements = computed(
  () =>
    rawNavigationElements.value?.find(
      (navigationElement) =>
        getTranslatedProperty(navigationElement, 'name')?.toLowerCase() ===
        'legal',
    )?.children || [],
);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
// const sendNewsletter = () => alert('TODO this');

const newsletterform = ref({
  email: '',
  validationError: '',
  showValidationError: false,
  showSuccess: false,
  successMessage: '',
});

async function sendNewsletter(e) {
  console.log(e);
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newsletterform.value.email)) {
    // Form submission will occur through the specified action  URL.
    newsletterform.value.showValidationError = false;
    newsletterform.value.validationError = ''; // Clear any previous error message

    await $fetch(e.target.action, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      body: newsletterform.value,
    })
      .then((result) => {
        console.log(result);
        newsletterform.value.showSuccess = true;
      })
      .catch((error) => {
        console.error('Contact form could not be send', error);
        newsletterform.value.validationError =
          'Leider ist ein Fehler aufgetreten. Versuche es später nochmal.';
        newsletterform.value.showValidationError = true;
      });
  } else {
    newsletterform.value.validationError = 'Bitte gib eine gültige E-Mail ein';
    newsletterform.value.showValidationError = true;
  }
}

const paymentProviders = ['visa', 'master-card', 'amex', 'klarna', 'paypal'];
</script>
<template>
  <footer>
    <menu class="bg-dark text-light">
      <div class="lg:px-15 mx-auto flex flex-col justify-center px-5">
        <div
          class="grid w-full grid-flow-row-dense grid-cols-1 gap-y-6 py-20 md:gap-x-6 xl:grid-cols-6 xl:grid-rows-none"
        >
          <div
            class="footer-nav-col flex max-w-[400px] flex-col gap-4 md:col-span-2"
          >
            <NuxtLink :to="formatLink(`/`)">
              <span class="sr-only">Shopware</span>
              <img v-lazy-load 
                class="h-6 w-auto sm:h-6"
                width="118"
                height="21"
                data-src="/logo/sn_logo_text_weiss.svg"
                alt="logo of the shop"
              />
            </NuxtLink>
            <p>
              Melde Dich für unseren Newsletter an und erhalte 10% Rabatt auf
              Deine erste Bestellung.
            </p>
            <!-- <div class="success-message" v-if="newsletterform.showSuccess">Bitte prüfe deinen Posteingang und bestätige deine Anmeldung.</div>
            <div v-else class="signup-form">
              <div class="relative">
                <form @submit.prevent="sendNewsletter" action="https://sn-supernatural.us3.list-manage.com/subscribe/post?u=8c566562c954a28011297a164&id=5654e21fd0&f_id=003e47e2f0">
                  <input class="p-4 w-full bg-dark-grey text-grey" type="email" placeholder="Deine E-Mail Adresse" v-model="newsletterform.email" required />
                  <button class="absolute right-0 h-full px-5 bg-dark border-1 border-white uppercase" @click="sendNewsletter()">abschicken</button>
                </form>
              </div>
              <div class="error-message text-red mt-2" v-if="newsletterform.showValidationError">
                {{ newsletterform.validationError }}
              </div>
            </div> -->
            <div id="mc_embed_shell">
              <div id="mc_embed_signup" class="signup-form">
                <form
                  id="mc-embedded-subscribe-form"
                  action="https://sn-supernatural.us3.list-manage.com/subscribe/post?u=8c566562c954a28011297a164&amp;id=5654e21fd0&amp;f_id=003e47e2f0"
                  method="post"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_self"
                >
                  <div id="mc_embed_signup_scroll" class="relative flex">
                    <div class="mc-field-group w-full grow">
                      <label for="mce-EMAIL" class="sr-only"
                        >Email Adresse</label
                      >
                      <input
                        id="mce-EMAIL"
                        type="email"
                        name="EMAIL"
                        class="required email bg-dark-grey text-grey w-full grow rounded-none p-4"
                        required
                        value=""
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        placeholder="Deine E-Mail Adresse"
                      />
                    </div>
                    <div
                      aria-hidden="true"
                      style="position: absolute; left: -5000px"
                    >
                      <input
                        type="text"
                        name="b_8c566562c954a28011297a164_5654e21fd0"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <input
                      id="mc-embedded-subscribe"
                      type="submit"
                      name="subscribe"
                      class="button bg-dark border-1 right-0 h-full rounded-none border-white px-5 py-4 uppercase"
                      value="Abschicken"
                    />
                  </div>
                  <div id="mce-responses" class="clear">
                    <div
                      id="mce-error-response"
                      class="response"
                      style="display: none"
                    />
                    <div
                      id="mce-success-response"
                      class="response"
                      style="display: none"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            class="col-span-4 grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-8 xl:grid-flow-col xl:grid-cols-none xl:divide-x"
          >
            <NavigationElement
              v-for="navigationElement in navigationElements?.slice(0, -2)"
              class="md:pl-10"
              :element="navigationElement"
            />
            <div class="footer-nav-col-last flex flex-col gap-10 md:pl-10">
              <NavigationElement
                v-for="navigationElement in navigationElements?.slice(-2)"
                :element="navigationElement"
              />
              <!-- <div class="flex gap-4">
                <p class="text-grey">Sprache</p>
                <LanguageSwitcher />
              </div> -->
            </div>
          </div>
        </div>
        <p class="text-grey pb-3">
          Alle Preise inkl. der gesetzl. MwSt. zzgl. Versandkosten und ggf.
          Nachnahmegebühren, wenn nicht anders angegeben.
        </p>
      </div>
      <div class="bg-dark-grey lg:px-15 px-5">
        <div
          class="text-grey mx-auto flex flex-col justify-between gap-8 py-4 xl:flex-row"
        >
          <div
            class="flex flex-col gap-0 divide-gray-500 md:divide-x lg:items-center xl:flex-row"
          >
            <p class="pr-4">
              © {{ new Date().getFullYear() }} super.natural Europe GmbH
            </p>
            <div
              class="mt-2 flex flex-col gap-2 divide-gray-500 md:flex-row md:divide-x lg:mt-0 lg:items-center lg:gap-0"
            >
              <NavigationLink
                v-for="legalNavigationElement in legalNavigationElements"
                :key="legalNavigationElement.id"
                :category="legalNavigationElement"
                class="py-2 md:px-4"
              />
            </div>
          </div>
          <div class="flex flex-wrap justify-between gap-2">
            <NuxtImg
              v-for="paymentProvider in paymentProviders"
              class="h-6 w-auto"
              :src="`/icons/${paymentProvider}.svg`"
              :alt="paymentProvider"
              width="34"
              height="21"
            />
          </div>
        </div>
      </div>
    </menu>
  </footer>
</template>
